<script>
    import Games from '@/services/Games';
    import Teams from '@/services/Teams';

    export default {
        props: {
            customer_id: String
        },
        data() {
            return {
                selectedMatch: '',
                matches: [],
                showLoader:false,
                showLoader1 : false ,
                score: [],
                modalData: {},
                LeaderboardData : {} ,
                selectedMatchDetails: {} ,
                home_team_logo : '' ,
                away_team_logo : '' ,
                home_team_name : ''  ,
                away_team_name : ''  ,
                position : '',
                home_team_score_guess : '' ,
                away_team_score_guess : '' ,
                participation : '' ,
                isBusy : false ,
                hasGuessTheScoreData : true ,
                hasGuessMomData : true ,
                hasMomData : '' ,
                MomData : '' ,
                MatchMomData : [] ,
                MatchMomDataAll : [] ,
                player_id : '' ,
                player_name : '' ,
                player_position : '' ,
                correct_vote : ''  ,
                loading: false,
                playerImage : '' ,
                imageKey: 0 ,
             }
        },
        mounted() {
            this.getLeaderboardData() ;
            this.matchesData();
        },
        watch: {
            selectedMatch(newMatch) {
                if (newMatch) {
                    this.getSelectedMatchDetails(newMatch);
                    this.getMatchMomData(newMatch) ;
                    this.getMatchMotm(newMatch);
                }
            }
        },
        methods: {

            async getLeaderboardData(){

                const queryString = `type=season&customerID=${this.customer_id}`;
                this.showLoader1 = true;

                await Games.getLeaderboardData(queryString).then(response => {
                        this.LeaderboardData = response.data;
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.toggleBusy();
                        this.showLoader = false;
                        this.showLoader1 = false;
                    })
            } ,
            getSelectedMatchDetails(matchId) {

                const allMatches = this.matches.flat();
                const selectedMatch = allMatches.find(match => match.match_id === matchId);
                if (selectedMatch) {
                    this.selectedMatchDetails = selectedMatch;
                } else {
                    this.selectedMatchDetails = null;
                }
            },
            async getMatches(type){
                try {
                    const response = await Teams.getFixtures(type)
                    if(response.data){
                        return response.data;
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

            async matchesData(){
                this.isBusy = true
                var upcomingMatches = await this.getMatches('UPCOMING');
                var pastMatches = await this.getMatches('PAST');
                var pastMatchTemp = [];
                var upcomingMatchTemp = [];

                if(upcomingMatches != null && upcomingMatches.fixtures.length > 0){
                    upcomingMatchTemp = upcomingMatches.fixtures;
                }
                if(pastMatches != null && pastMatches.fixtures.length > 0){
                    pastMatchTemp = pastMatches.fixtures;
                }
                this.matches = upcomingMatchTemp.concat(pastMatchTemp);
            },

            async getFanExperienceData(){

                this.MomData = []  ;
                this.score   = []  ;
                this.hasGuessTheScoreData = false  ;
                this.hasGuessMomData  = false ;

                this.loading = true;

                try {

                    await Games.exportMotmData(this.selectedMatch,{
                        params: {
                            customer_id : this.customer_id
                        }
                    }).then(response => {

                        if (response) {
                            this.MomData = response.data;

                            if(this.MomData[1] !== '') {
                                this.hasGuessMomData = true;
                            }else
                                { this.hasGuessMomData  == false ;
                            }
                        }
                    })
                    .catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.hasGuessMomData  = false ;
                    })

                    await Games.getScorePrediction(this.customer_id, {
                        params: {
                            match_id: this.selectedMatch
                        }
                        }).then(response => {
                            this.score = response.data.data;
                            this.hasGuessTheScoreData = true;
                        })
                        .catch(error => {
                            this.hasGuessTheScoreData = false ;
                            this.error = error.response.data.error ? error.response.data.error : "";
                        })

                } catch (error) {
                    console.error("Error:", error);
                } finally {
                    this.loading = false;
                }
            },

            async getMatchMotm(selectedMatch) {
                try {
                    const response = await Games.getMatchMotm(selectedMatch);
                    if (response.data.data) {
                        this.MatchMomDataAll = response.data.data;

                        const selectedPlayer = this.MatchMomDataAll.find(player => player.player_id === this.player_id);

                        if (selectedPlayer && selectedPlayer.player_data && selectedPlayer.player_data.length > 0) {
                            const playerData = selectedPlayer.player_data[0];
                            this.playerImage = playerData.img;
                            this.imageKey += 1;
                        }
                    } else {
                        this.MatchMomDataAll = [];
                    }

                    this.toggleBusy();
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusy();
                }
            },
            async getMatchMomData(selectedMatch){
                Games.getMatchMotmData(this.selectedMatch).then(response => {
                    if(response.data.data){
                        this.MatchMomData = response.data.data;
                        this.player_id =  this.MatchMomData.motmPlayer.player_id;
                    }else{
                        this.MatchMomData = [];
                    }
                    this.toggleBusy();
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusy();
                }) ;
            }

        }
    }
</script>

<template>
    <div class="row mb-5">
        <div class="col-sm-12 col-md-12">

            <div class="row">
                <div class="row">
                    <div class="col-sm-12" style="font-size: 17px; font-weight: bold;">
                        <h5 class="text-center">Season tickets for {{LeaderboardData.type_id}}</h5>
                    </div>
                    <div class="row" >

                        <div class="row mb-5 d-flex justify-content-around">

                            <div class="col-md-3">
                                <div class="card bg-gradient-light h-80">
                                    <div class="card-body d-grid">
                                        <div>
                                            <h5 class="text-muted mb-0 text-center">Position</h5>
                                        </div>
                                        <h4 class="mt-3 align-self-end text-center" v-if="LeaderboardData && LeaderboardData.customer_placement">
                                            <span data-plugin="counterup" v-if="!showLoader1">
                                                {{ this.LeaderboardData.customer_placement.position }}
                                            </span>
                                            <div class="container-fluid d-flex justify-content-center align-items-center h-50"
                                                 v-else>

                                                <b-spinner large></b-spinner>

                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card bg-gradient-light h-80">
                                    <div class="card-body d-grid">
                                        <div>
                                            <h5 class="text-muted mb-0 text-center">Points</h5>
                                        </div>
                                        <h4 class="mt-3 align-self-end text-center"  v-if="LeaderboardData && LeaderboardData.customer_placement">
                                                        <span data-plugin="counterup" v-if="!showLoader1">
                                                            {{LeaderboardData.customer_placement.score}}
                                                        </span>
                                            <div class="container-fluid d-flex justify-content-center align-items-center h-50"
                                                 v-else>
                                                <b-spinner large></b-spinner>

                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card bg-gradient-light h-80">
                                    <div class="card-body d-grid">
                                        <div>
                                            <h5 class="text-muted mb-0 text-center">Participation</h5>
                                        </div>
                                        <h4 class="mt-3 align-self-end text-center" v-if="LeaderboardData && LeaderboardData.customer_placement">
                                                        <span data-plugin="counterup" v-if="!showLoader1">
                                                            {{LeaderboardData.customer_placement.participation}} / {{LeaderboardData.customer_placement.matches_played}}
                                                        </span>
                                            <div class="container-fluid d-flex justify-content-center align-items-center h-50"
                                                 v-else>
                                                <b-spinner large></b-spinner>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <div class="mb-6 col-md-6  mx-auto">
                    <div class="form-row form-group mb-3">
                        <label class="col-md-12 text-center col-form-label">Select Match</label>
                        <select class="form-select" v-model="selectedMatch" @change="getFanExperienceData()"
                                id="selectMatch">
                            <option v-for="match in matches" :key="match.match_id" :value="match.match_id">
                                {{match.home_team_name}} - {{match.away_team_name}} / <span class="ms-2">{{match.date_time}}</span>
                            </option>
                        </select>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="selectedMatchDetails">
            <h4 class="text-center mt-5">Match Details</h4>
            <div class="row">
                <div class="fe-crm text-center d-flex justify-content-center align-items-center">
                    <div class="col-md-3" v-if="selectedMatchDetails && selectedMatchDetails.home_team_logo">
                        <div class="team-holder">
                            <img class="img-responsive" :src="selectedMatchDetails.home_team_logo"
                                 style="max-width: 50%" alt="Home Team Logo"/>
                            <p>{{selectedMatchDetails.home_team_name}}</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <span class="date label label-info label-sm">{{selectedMatchDetails.date_time}}</span>
                            <div class="clearfix"></div>
                            <div class="col-lg-5 text-center home-result" v-if="selectedMatchDetails && selectedMatchDetails.score">
                                <span style="font-size: 18px; font-weight: 800">{{selectedMatchDetails.score.ft_score_home}}</span>
                            </div>
                            <div class="col-lg-3 vs"  v-if="selectedMatchDetails && selectedMatchDetails.score">:</div>
                            <div class="col-lg-4 text-center away-result"  v-if="selectedMatchDetails && selectedMatchDetails.score">
                                <span style="font-size: 18px; font-weight: 800">{{selectedMatchDetails.score.ft_score_away}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="team-holder right" v-if="selectedMatchDetails && selectedMatchDetails.away_team_logo">
                            <img class="img-responsive" :src="selectedMatchDetails.away_team_logo"
                                 style="max-width: 50%" alt="Away Team Logo"/>
                            <p>{{selectedMatchDetails.away_team_name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>

        <div v-if="loading" class="text-center">Loading...</div>


        <div class="row" v-else>
            <div class="col-6">
                <div v-if="MatchMomData.motmPlayer && MatchMomData.motmPlayer.player_name !== ''">

                <!--<div v-if="MatchMomData.motmPlayer.player_name !== '' ">-->
                    <h4 style="margin-bottom: 10px;" class="text-center">Man of the Match </h4>
                    <h5 class="text-center">{{ MatchMomData.motmPlayer.player_name }}</h5>
                    <img v-if="playerImage" :src="playerImage + '?key=' + imageKey" class="mx-auto d-block"
                         alt="Player Image" style="max-width: 200px; max-height: 600px;">
                    <h6 class="text-center mt-3">{{ MatchMomData.motmPlayer.player_position }}</h6>
                </div>
                <div v-else>
                    <p style="font-size: 16px; " class="text-center">No player chosen for Man of the match</p>
                </div>

                <div v-if="MomData[1] && MomData[1].length > 0 && hasGuessMomData">
                    <div v-for="(item, index) in MomData[1]" :key="index">
                        <p class="text-center">Voted Player: {{ item.player_name }}</p>
                        <p class="text-center">Correct Vote: {{ item.correct_vote }}</p>
                        <hr/>
                    </div>
                </div>
                <div v-else>
                    <p style="font-size: 16px;" class="text-center">No Man of the match prediction.</p>
                </div>
            </div>
            <div class="col-6">
                <div>

                    <div class="row" v-if="hasGuessTheScoreData == true">
                        <h4 class="text-center">Guess the score Prediction</h4>
                        <div class="col-12 text-center" v-if="score.length > 0"><h5>Home Team {{
                            score[0].home_team_score_guess }} - {{ score[0].away_team_score_guess }} Away Team
                        </h5></div>
                    </div>
                    <div v-else>
                        <p style="font-size: 16px;" class="text-center">No Guess the score prediction .</p>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
